import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import styled from 'styled-components';
import { motion } from 'framer-motion';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

// Styles for the sections
const VisualSection = styled(motion.section)`
  padding: 50px 20px;
  background-color: #111;
  color: #fff;
`;

const TextWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const TitleText = styled.h2`
  font-size: 2.5rem;
  color: #00d1d1;
  margin-bottom: 10px;
  font-weight: 600;
`;

const SubTitleText = styled.p`
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 10px;
`;

const DisclaimerText = styled.p`
  font-size: 0.9rem;
  color: #aaa;
  margin-top: -10px;
`;

const ChartWrapper = styled.div`
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #222;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`;

// Data for Processing Time per Image
const processingTimeData = {
  labels: ['Traditional Radiology', 'IntelVis AI'],
  datasets: [
    {
      label: 'Processing Time per Image (Seconds)',
      data: [600, 5], // Traditional radiology ~10 minutes, IntelVis AI ~5 seconds
      backgroundColor: 'rgba(255, 99, 132, 0.6)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1,
      barThickness: 60,
    },
  ],
};

// Data for Error Rate
const errorRateData = {
  labels: ['Traditional Radiology', 'IntelVis AI'],
  datasets: [
    {
      label: 'Error Rate (%)',
      data: [10, 3], // Traditional 10%, IntelVis AI 3%
      backgroundColor: 'rgba(54, 162, 235, 0.6)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
      barThickness: 60,
    },
  ],
};

// Data for Detection Accuracy
const accuracyData = {
  labels: ['Traditional Radiology', 'IntelVis AI'],
  datasets: [
    {
      label: 'Detection Accuracy (%)',
      data: [85, 98], // IntelVis AI higher accuracy
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
      barThickness: 60,
    },
  ],
};

// Data for Diagnostic Speed Improvement
const speedImprovementData = {
  labels: ['Traditional Radiology', 'IntelVis AI'],
  datasets: [
    {
      label: 'Diagnostic Speed Improvement (%)',
      data: [0, 50], // IntelVis improves diagnostic speed by 50%
      backgroundColor: 'rgba(255, 206, 86, 0.6)',
      borderColor: 'rgba(255, 206, 86, 1)',
      borderWidth: 1,
      barThickness: 60,
    },
  ],
};

// Data for Real-World Case Study (Missed Diagnoses Reduction)
const caseStudyData = {
  labels: ['Missed Diagnoses with Traditional Radiology', 'Missed Diagnoses with IntelVis AI'],
  datasets: [
    {
      label: 'Missed Diagnoses (%)',
      data: [12, 1], // IntelVis AI reduces missed diagnoses
      backgroundColor: ['rgba(153, 102, 255, 0.6)', 'rgba(255, 99, 132, 0.6)'],
      borderColor: ['rgba(153, 102, 255, 1)', 'rgba(255, 99, 132, 1)'],
      borderWidth: 1,
    },
  ],
};

// Chart options for all visualizations
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        color: '#fff',
      },
      title: {
        display: true,
        text: 'Value',
        color: '#fff',
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'top',
      labels: {
        color: '#fff',
      },
    },
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      bodyFont: { size: 16 },
      borderColor: '#fff',
      borderWidth: 1,
    },
  },
};

const Visualizations = () => (
  <VisualSection>
    {/* Processing Time per Image */}
    <TextWrapper>
      <TitleText>Processing Time per Image</TitleText>
      <SubTitleText>
        IntelVis AI processes medical images in just 5 seconds, compared to traditional radiology's 10 minutes.
      </SubTitleText>
      <DisclaimerText>Prototype model based off singular internal study</DisclaimerText>
    </TextWrapper>
    <ChartWrapper>
      <Bar data={processingTimeData} options={chartOptions} />
    </ChartWrapper>

    {/* Error Rate Comparison */}
    <TextWrapper>
      <TitleText>Error Rate Comparison</TitleText>
      <SubTitleText>
        IntelVis AI reduces diagnostic errors, offering a lower error rate compared to traditional methods.
      </SubTitleText>
      <DisclaimerText>Prototype model based off singular internal study</DisclaimerText>
    </TextWrapper>
    <ChartWrapper>
      <Bar data={errorRateData} options={chartOptions} />
    </ChartWrapper>

    {/* Detection Accuracy Comparison */}
    <TextWrapper>
      <TitleText>Detection Accuracy Comparison</TitleText>
      <SubTitleText>
        IntelVis AI delivers a higher detection accuracy, helping doctors make more reliable diagnoses.
      </SubTitleText>
      <DisclaimerText>Prototype model based off singular internal study</DisclaimerText>
    </TextWrapper>
    <ChartWrapper>
      <Bar data={accuracyData} options={chartOptions} />
    </ChartWrapper>

    {/* Diagnostic Speed Improvement */}
    <TextWrapper>
      <TitleText>Diagnostic Speed Improvement</TitleText>
      <SubTitleText>
        IntelVis AI speeds up diagnosis by 50%, reducing wait times and improving patient care.
      </SubTitleText>
      <DisclaimerText>Prototype model based off singular internal study</DisclaimerText>
    </TextWrapper>
    <ChartWrapper>
      <Bar data={speedImprovementData} options={chartOptions} />
    </ChartWrapper>

    {/* Real-World Case Study: Missed Diagnoses */}
    <TextWrapper>
      <TitleText>Real-World Case Study: Missed Diagnoses</TitleText>
      <SubTitleText>
        IntelVis AI reduces missed diagnoses from 12% to just 1%, significantly improving diagnostic accuracy.
      </SubTitleText>
      <DisclaimerText>Prototype model based off singular internal study</DisclaimerText>
    </TextWrapper>
    <ChartWrapper>
      <Pie data={caseStudyData} options={chartOptions} />
    </ChartWrapper>
  </VisualSection>
);

export default Visualizations;
