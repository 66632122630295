import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// Styles for the hero section
const HeroSection = styled.section`
  min-height: 100vh;
  background: url('/images/medical-hero.jpg') center/cover no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 50px;
  padding: 20px; /* General padding for better mobile view */

  @media (max-width: 768px) {
    padding: 15px; /* Reduced padding for mobile */
  }
`;

const HeroContent = styled.div`
  max-width: 800px;
  color: #fff;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0 15px; /* Less padding on mobile */
  }
`;

const HeroTitle = styled(motion.h1)`
  font-size: 4rem;
  margin-bottom: 20px;
  color: #00d1d1;

  @media (max-width: 768px) {
    font-size: 2.5rem; /* Smaller font size on mobile */
  }
`;

const HeroText = styled(motion.p)`
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1.2rem; /* Smaller font size for mobile */
    margin-bottom: 20px;
  }
`;

// Container for the buttons
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack buttons vertically on mobile */
    gap: 15px;
    margin-bottom: 30px;
  }
`;

const CTAButton = styled(motion(Link))`
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: #00d1d1;
  color: #111;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #00a7a7;
  }

  @media (max-width: 768px) {
    padding: 12px 25px; /* Slightly smaller buttons on mobile */
    font-size: 1rem; /* Smaller font size for buttons on mobile */
  }
`;

// About Us section
const AboutUsSection = styled.div`
  background-color: #222;
  padding: 40px 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin-top: 30px;

  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const AboutTitle = styled.h2`
  font-size: 2.5rem;
  color: #00d1d1;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const AboutText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Home = () => {
  return (
    <HeroSection>
      <HeroContent>
        <HeroTitle
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          AI-Powered Medical Diagnostics
        </HeroTitle>
        <HeroText
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          IntelVis uses state-of-the-art AI technology to deliver faster, more accurate medical imaging diagnostics and real-time object detection.
        </HeroText>

        {/* Button Container with Learn More and Contact */}
        <ButtonContainer>
          <CTAButton
            to="/products"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Learn More
          </CTAButton>
          <CTAButton
            to="/contact"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Contact Us
          </CTAButton>
        </ButtonContainer>

        {/* About Us Section directly below the buttons */}
        <AboutUsSection>
          <AboutTitle>About IntelVis</AboutTitle>
          <AboutText>
            IntelVis is at the forefront of revolutionizing medical diagnostics with AI-driven solutions. 
            Our mission is to enhance diagnostic accuracy and speed, allowing healthcare professionals to provide better and faster care. 
            Through real-time object detection and cutting-edge medical imaging diagnostics, we are empowering the future of healthcare.
          </AboutText>
        </AboutUsSection>
      </HeroContent>
    </HeroSection>
  );
};

export default Home;
