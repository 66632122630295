import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Container for the contact section
const ContactSection = styled.section`
  padding: 80px 20px;
  background-color: #111;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleText = styled.h2`
  font-size: 2.8rem;
  color: #00d1d1;
  margin-bottom: 20px;
`;

const SubTitleText = styled.p`
  font-size: 1.2rem;
  color: #bbb;
  margin-bottom: 30px;
`;

const EmailLink = styled(motion.a)`
  font-size: 1.5rem;
  color: #00d1d1;
  text-decoration: none;
  border: 2px solid #00d1d1;
  padding: 10px 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #00d1d1;
    color: #111;
  }
`;

const Contact = () => {
  return (
    <ContactSection>
      <TitleText>Contact Us</TitleText>
      <SubTitleText>
        We're here to help! Feel free to reach out to us via email, and we'll get back to you as soon as possible.
      </SubTitleText>

      <EmailLink
        href="mailto:kyle@intelvis.ai"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        kyle@intelvis.ai
      </EmailLink>
    </ContactSection>
  );
};

export default Contact;
