import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// Animation variants for product items
const fadeIn = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } }
};

const hoverEffect = {
  scale: 1.02,
  transition: { duration: 0.3, ease: 'easeInOut' }
};

const ProductsSection = styled.section`
  padding: 70px 20px;
  background-color: #111;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleText = styled.h2`
  font-size: 2.8rem;
  color: #00d1d1;
  margin-bottom: 50px;
  text-align: center;
  letter-spacing: 1.2px;
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
`;

const ProductItem = styled(motion.div)`
  background-color: #222;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
    background-color: #1b1b1b;
  }
`;

const ProductTitle = styled.h3`
  font-size: 1.8rem;
  color: #00d1d1;
  margin-bottom: 20px;
`;

const ProductDescription = styled.p`
  font-size: 1.2rem;
  color: #bbb;
  line-height: 1.6;
  margin-bottom: 30px;
`;

const DataButton = styled(motion(Link))`
  display: inline-block;
  padding: 12px 30px;
  margin-top: 10px;
  font-size: 1rem;
  background-color: #00d1d1;
  color: #111;
  text-align: center;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #00a7a7;
  }
`;

const Products = () => (
  <ProductsSection>
    <TitleText>Our Solutions</TitleText>

    <ProductWrapper>
      {/* Medical Imaging Diagnostics */}
      <ProductItem
        variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        <ProductTitle>Medical Imaging Diagnostics</ProductTitle>
        <ProductDescription>
          IntelVis AI powers cutting-edge medical imaging diagnostics, enabling faster and more accurate analysis of MRI, CT scans, and X-rays. Our AI solutions reduce processing time to seconds while minimizing diagnostic errors, empowering healthcare professionals to provide better care for patients.
        </ProductDescription>
       
       
      </ProductItem>

      {/* Real-Time Object Detection for Medical Purposes */}
      <ProductItem
        variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        <ProductTitle>Real-Time Object Detection in Medical Imaging</ProductTitle>
        <ProductDescription>
          Our real-time object detection system identifies and classifies critical features in medical scans with high accuracy, helping radiologists and surgeons make rapid, informed decisions during treatment. IntelVis AI integrates seamlessly into existing workflows, providing real-time insights when it matters most.
        </ProductDescription>
        
      </ProductItem>
    </ProductWrapper>
    <DataButton
          to="/visualizations"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          See Data
        </DataButton>
  </ProductsSection>
);

export default Products;
