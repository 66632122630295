import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'; // Import framer-motion

// Navbar container
const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #111;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

// Brand Logo
const Logo = styled.h1`
  color: #00d1d1;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

// Links container for desktop
const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  gap: 30px;

  @media (max-width: 768px) {
    display: none;
  }
`;

// Mobile Menu Toggle (Hamburger)
const MobileMenuIcon = styled.div`
  display: none;
  font-size: 2rem;
  color: #00d1d1;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

// Mobile Menu Dropdown with Framer Motion animation
const MobileMenu = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: rgba(17, 17, 17, 0.9);
  position: absolute;
  top: 70px;
  right: 20px;
  width: 200px;
  border-radius: 10px;
  padding: 10px 0;
  z-index: 1000;

  @media (min-width: 769px) {
    display: none;
  }
`;

// Mobile Links in the mobile menu
const MobileNavLink = styled(Link)`
  font-size: 1.2rem;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;

  &:hover {
    background-color: #222;
  }
`;

// Desktop NavLink
const NavLink = styled(Link)`
  font-size: 1.2rem;
  color: #fff;
  transition: color 0.3s ease;

  &:hover {
    color: #00d1d1;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <NavbarContainer>
      <Logo>IntelVis</Logo>

      {/* Desktop Links */}
      <NavLinks>
        <li><NavLink to="/">Home</NavLink></li>
        <li><NavLink to="/products">Products</NavLink></li>
        <li><NavLink to="/visualizations">Visualizations</NavLink></li>
  
        <li><NavLink to="/contact">Contact</NavLink></li>
      </NavLinks>

      {/* Mobile Menu Toggle */}
      <MobileMenuIcon onClick={toggleMenu}>
        ☰
      </MobileMenuIcon>

      {/* Mobile Menu with animation */}
      <MobileMenu
        initial={{ opacity: 0, y: -20 }} // Starting position
        animate={menuOpen ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }} // Animation states
        transition={{ duration: 0.3 }} // Animation duration
      >
        <MobileNavLink to="/" onClick={toggleMenu}>Home</MobileNavLink>
        <MobileNavLink to="/products" onClick={toggleMenu}>Products</MobileNavLink>
        <MobileNavLink to="/visualizations" onClick={toggleMenu}>Visualizations</MobileNavLink>
        <MobileNavLink to="/contact" onClick={toggleMenu}>Contact</MobileNavLink>
      </MobileMenu>
    </NavbarContainer>
  );
};

export default Navbar;
