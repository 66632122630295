import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useTexture } from '@react-three/drei';

function MoonMesh() {
  // Load the Moon texture inside the Canvas component
  const moonTexture = useTexture('/2k_moon.jpg'); // Local moon texture in public folder
  
  return (
    <mesh>
      <sphereGeometry args={[3, 64, 64]} /> {/* Larger sphere */}
      <meshStandardMaterial map={moonTexture} /> {/* Apply the Moon texture */}
    </mesh>
  );
}

const Moon = () => {
  return (
    <div style={{ height: '100vh', backgroundColor: '#000' }}>
      <div style={{ textAlign: 'center', paddingTop: '20px' }}>
        <h1 style={{ fontFamily: 'serif', fontSize: '3em', color: '#fff' }}>
          The moon is beautiful, isn’t it?
        </h1>
      </div>
      {/* Three.js rendering context for 3D elements */}
      <Canvas camera={{ position: [0, 0, 10] }} style={{ height: '85vh' }}>
        {/* Add lighting for the moon */}
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 10, 5]} intensity={1} />
        
        {/* Load the Moon mesh with the texture */}
        <Suspense fallback={null}>
          <MoonMesh />
        </Suspense>

        {/* Allows users to rotate/zoom the 3D Moon */}
        <OrbitControls />
      </Canvas>
    </div>
  );
};

export default Moon;
