import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Products from './components/Products';
import Visualizations from './components/Visualizations';
import Contact from './components/Contact';
import Moon from './components/Moon'; // Import the new Moon component
import GlobalStyle from './GlobalStyle';

const App = () => {
  return (
    <Router>
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/visualizations" element={<Visualizations />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/moon" element={<Moon />} /> {/* New route for the Moon */}
      </Routes>
    </Router>
  );
};

export default App;
